import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import Layout2 from "../components/Layout2";
import Seo from "../components/Seo"; // Import the Seo component
import ShareLinks from "../components/ShareLinks";
import Comp from "../components/alafasy/index";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"; // Import useLocation
import { useTranslation } from "gatsby-plugin-react-i18next";
import CookieConsent from "react-cookie-consent";

const AuthorReciter = ({ data }) => {
  let tempSlug = "";
  const reciter = data.authorReciterJson;
  let _matched;
  const location = useLocation();

  if (location.pathname.includes("translations")) {
    _matched = data.allAllTranslationsJson.nodes.filter(
      (item) => item.title === data.allAuthorTranslationsJson.edges[0].node.title
    );
    const _path = location.pathname.split('/').filter((item) => item !== "");
    tempSlug = `/${_path[_path.length - 1]}/`;
  } else {
    _matched = data.allAllRecitersJson.nodes.filter(
      (item) => item.title === reciter.title
    );
    tempSlug = reciter?.fields?.slug;
  }

  const matched = _matched;
  const { t, i18n } = useTranslation();

  // Extract siteUrl from the site metadata
  const siteUrl = data.site.siteMetadata.siteUrl;

  useEffect(() => {
    console.log('matched', reciter?.fields?.slug);
  }, []);

  if (matched.length === 0) {
    return (
      <Layout2 pageTitle="Page Not Found">
        <div className="main">
          <div className="error-content-wrap text-center">
            {t(
              "Unfortunately, the requested page does not appear to exist or has been moved."
            )}
            <br />
            {t(
              "Please double-check the URL or navigate to the homepage to explore other content."
            )}
          </div>
        </div>
      </Layout2>
    );
  } else {
    const reciterData = matched[0];
    const isTranslation = location.pathname.includes("translations");
    const pathPrefix = isTranslation ? "/translations" : "/reciter";

    return (
      <Layout2 pageTitle={reciterData.title}>
        <Helmet>
          {/* Add hrefLang attribute in the head */}
          <link rel="alternate" hrefLang="en" href={`${siteUrl}${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="ar" href={`${siteUrl}/ar${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="fr" href={`${siteUrl}/fr${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="es" href={`${siteUrl}/es${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="de" href={`${siteUrl}/de${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="ur" href={`${siteUrl}/ur${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="ru" href={`${siteUrl}/ru${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="tr" href={`${siteUrl}/tr${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="zh" href={`${siteUrl}/zh${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="pt" href={`${siteUrl}/pt${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="it" href={`${siteUrl}/it${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="id" href={`${siteUrl}/id${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="bn" href={`${siteUrl}/bn${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="nl" href={`${siteUrl}/nl${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="hi" href={`${siteUrl}/hi${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="fa" href={`${siteUrl}/fa${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="ug" href={`${siteUrl}/ug${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="so" href={`${siteUrl}/so${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="sw" href={`${siteUrl}/sw${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="no" href={`${siteUrl}/no${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="da" href={`${siteUrl}/da${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="se" href={`${siteUrl}/se${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="ha" href={`${siteUrl}/ha${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="bs" href={`${siteUrl}/bs${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="th" href={`${siteUrl}/th${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="tg" href={`${siteUrl}/tg${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="tl" href={`${siteUrl}/tl${pathPrefix}${tempSlug}`} />
          <link rel="alternate" hrefLang="x-default" href={`${siteUrl}${pathPrefix}${tempSlug}`} />
        </Helmet>

        <Seo
          description={t(reciterData.metaDescription)}
          title={t(reciterData.metaTitle)}
          image={reciterData.image}
          url={location.href}
          reciterData={reciterData}
        />
        <div className="main">
          <div className="text-center blue-bg">
            <h1 className="h3 post-title">{t(reciterData.title)}</h1>
            <div className="description">
              <Link
                to={reciterData.downloadAll}
                target="_blank"
                className="btn btn-secondary lbtn"
              >
                <span>{t("Download All Surahs")}</span>
              </Link>
            </div>
          </div>
          <div className="share-section">
            <ShareLinks url={location.href} title={reciterData.title} data={reciterData} />
          </div>
          <div className="container">
            <Comp
              t={t}
              songs={reciterData.reciters}
              author={reciterData.title}
            />
          </div>
          <div className="container">
            <div className="additional-info">
              <h3 className="additional-info-title">{t(reciterData.title)}</h3>
              <p className="additional-info-description">{t(reciterData.metaDescription)}</p>
            </div>
          </div>
          <CookieConsent
            location="bottom"
            buttonText={t("Accept Cookies")}
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            {t("This website uses cookies to enhance the user experience.")}
          </CookieConsent>
        </div>
      </Layout2>
    );
  }
};

export default AuthorReciter;

export const query = graphql`
  query authorReciter($slug: String!, $language: String!) {
    authorReciterJson(fields: { slug: { eq: $slug } }) {
      ...AuthorReciterQueryFragment
    }
    allAllRecitersJson {
      nodes {
        title
        metaDescription
        downloadAll
        private
        metaTitle
        reciters {
          trackName
          trackURL
          downloadLink
          surahNo
        }
      }
    }
    allAuthorTranslationsJson(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          title
        }
      }
    }
    allAllTranslationsJson {
      nodes {
        title
        metaDescription
        downloadAll
        private
        metaTitle
        reciters {
          trackName
          trackURL
          downloadLink
          surahNo
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
