import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
import NewsletterSubscription from "./NewsletterSubscription"
import SearchPopup from "./SearchPopup"
import SearchPopupContext from "../util/searchPopupContext"
import ShareLinks from "./ShareLinks"
import Banner from "../templates/banner"
import BottomBanner from "../templates/bottomBanner"
const Layout2 = ({ children, pageTitle }) => {
  const [popupVisible, setPopupVisible] = React.useState(false)
  const { site, markdownRemark } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          stickyNav
        }
      }
      markdownRemark {
        frontmatter {
          title
        }
      }
    }
  `)
  const { stickyNav } = site.siteMetadata
  const currentArticleTitle = pageTitle || markdownRemark.frontmatter.title;

  return (
    <div className="site-wrap" data-nav={stickyNav ? "sticky" : ""}>
      <SearchPopupContext.Provider value={{ popupVisible, setPopupVisible }}>
        <Header />
        {children}
        {typeof window !== 'undefined' && (
          <ShareLinks url={window.location.href} title={`${currentArticleTitle}`} />
        )}
        <NewsletterSubscription />
        <BottomBanner/>
        <Footer />
        <SearchPopup />
      </SearchPopupContext.Provider>
    </div>
  )
}

export default Layout2
graphql`
query ($language: String!) {
  locales: allLocale(
    filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
  ) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;