import React, { useState, useEffect, useRef } from 'react';
import { MdPlayArrow, MdPause, MdDownload, MdRefresh } from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import cn from 'classnames'; // Importing 'cn' for classNames
import LazyLoad from 'react-lazyload';
import AudioProgressBar from './AudioProgressBar';
import { getAudioDurationInSeconds } from '@remotion/media-utils';
import { formatDurationDisplay } from '../audio/util';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useWindowSize = () => {
  const isSSR = typeof window === 'undefined';
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 0 : window.innerWidth,
    height: isSSR ? 0 : window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    if (!isSSR) {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isSSR]);

  return windowSize;
};

const TrackItem = ({
  title,
  author,
  trackNumberLabel,
  selected,
  onClick,
  link_url,
  playing,
  progress,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [trackTime, setTrackTime] = useState('');
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
const {t,i18n} = useTranslation()
  const { width } = useWindowSize();

  const dropDownRef = useRef();
  const activatorRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const seconds = await getAudioDurationInSeconds(link_url);
        setDuration(seconds);
        setTrackTime(formatDurationDisplay(seconds));
      } catch (error) {
        console.error('Error fetching audio duration:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selected || isShown) {
      fetchData();
    }
  }, [link_url, selected, isShown]);

  useEffect(() => {
    if (!dropDownRef.current || !activatorRef.current) return;
    const handleClickOutside = (event) => {
      if (
        !dropDownRef.current ||
        !activatorRef.current ||
        dropDownRef.current.contains(event.target) ||
        activatorRef.current.contains(event.target)
      ) {
        return;
      }

      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, dropDownRef, activatorRef]);

  const handleRefreshClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const isExternalLink = link_url.startsWith('https');

  const linkComponent = isExternalLink ? (
    <a href={link_url} className="btn sbtn" target="_blank" rel="noopener noreferrer">
      <MdDownload size={24} />
    </a>
  ) : (
    <Link hrefLang={i18n.language} to={link_url} className="btn sbtn">
      <MdDownload size={24} />
    </Link>
  );

  return (
    <LazyLoad height={50} offset={100}>
      <div>
        <ul>
          <li
            onClick={onClick}
            onMouseEnter={() => width > 600 && setIsShown(true)}
            onMouseLeave={() => width > 600 && setIsShown(false)}
            className={`list-group ${selected ? 'bg-cyan-600 text-white list-group-selected' : ''} ${
              !selected ? 'hover:bg-cyan-600 hover:text-white' : ''
            }`}
          >
            <div className="item-content">
              <div className="item-content--material">
                <div className="item-content--ppcontrol">
                  {selected && playing ? <MdPause size={20} /> : <MdPlayArrow size={20} />}
                </div>
                <div className="item-content--meta">
                  <div className="item-content--meta-title">
                    {trackNumberLabel} - {t(title)}
                  </div>
                  <div className="item-content--meta-artist">{t(author)}</div>
                </div>
              </div>
              {width > 600 && (
                <div className="item-content--actions">
                  <div className="item-content--actions-wrapper">
                    <div className={isShown ? 'action-btn' : 'action-btn invisible'}>
                      <Link hrefLang={i18n.language} to="/all-reciters/" className="btn btn-secondary sbtn">
                        <MdRefresh size={24} onClick={handleRefreshClick} />
                      </Link>
                    </div>
                    <div className={isShown ? 'action-btn' : 'action-btn invisible'}>
                      {linkComponent}
                    </div>
                  </div>
                </div>
              )}
              <div className="item-content--timer">
                <div className="item-content--timer-progress">
                  {isLoading
                    ? <p>{t("Loading...")}</p>
                    : selected
                    ? `${formatDurationDisplay(progress)} / ${trackTime}`
                    : trackTime}
                </div>
              </div>
              {width <= 600 && (
                <div className="item-content--dropdown">
                  <button
                    className="dropdown-button"
                    onClick={() => setIsOpen(!isOpen)}
                    ref={activatorRef}
                    aria-label="Toggle Dropdown"
                  >
                    <FontAwesomeIcon icon={faCircleChevronDown} className="icon-dropdown"></FontAwesomeIcon>
                  </button>
                  <div
                    className="dropdown-content"
                    style={{ display: isOpen ? 'flex' : 'none' }}
                    ref={dropDownRef}
                  >
                    <Link hrefLang={i18n.language} to="/all-reciters" className="link">
                      Other Qaris
                    </Link>
                    {linkComponent}
                  </div>
                </div>
              )}
            </div>
            {selected && (
              <div className="progress-container">
                <AudioProgressBar duration={duration} currentProgress={progress} />
              </div>
            )}
          </li>
        </ul>
      </div>
    </LazyLoad>
  );
};

export default TrackItem;
